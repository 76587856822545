<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
    <div>



        <sweet-modal ref="pageremovemodal" @close="closeNested" title="Remove Facebook Page">

            <div v-if="showNested && selectedDeletePage">

                <p>Are you sure you want to remove this page <b>{{ selectedDeletePage.name }}</b>? This will also remove
                    all the attached instagram pages from YetuShare and cancel all your scheduled posts.</p>



                <a-row>

                    <a-col :span="10" style="margin: 5px;">
                        <a-button type="danger" @click="removePage" block>Yes</a-button>
                    </a-col>

                    <a-col :span="10" style="margin: 5px;">
                        <a-button type="secondary" @click="$refs.pageremovemodal.close()" block>No</a-button>
                    </a-col>


                </a-row>

                <a-col :span="24">
                    <vue-loaders-ball-beat v-if="loading" color="green" scale="1"></vue-loaders-ball-beat>
                </a-col>

            </div>


        </sweet-modal>


        <sweet-modal ref="createpagemodal" @close="closeNested" title="Add a Page" width="70%">

            <div v-if="showNested">



                <p v-if="livePages.length > 0">Click the button below to add a facebook page</p>

                <p v-if="livePages.length < 1">No Facebook Accounts found, check your token status or that the app is
                    allowed
                    access</p>


                <a-row :gutter="24" type="flex" v-if="!loading">

                    <!-- Authors Table Column -->
                    <a-col :span="7" v-for="item in livePages" v-bind:key="item.id" style="margin: 7px;">


                        <WidgetProject :title="item.name" :content="item.category" :project_id="item.id"
                            project_type="facebook" :addItem="doAddPage">

                        </WidgetProject>

                    </a-col>

                </a-row>

                <vue-loaders-ball-beat v-if="loading" color="green" scale="1"></vue-loaders-ball-beat>

            </div>
        </sweet-modal>



        <sweet-modal ref="pagestatsmodal" @close="closeNested" title="Page Stats" width="70%">

<div v-if="showNested">

    <a-card>

        <a-row :gutter="24" type="flex">

            <a-col :span="18" style="margin-top: 17px;">

                <br>

                <p>Stats this past week</p>
                <br>



                <b-select v-model="currentSelectedInsight" :options="currentPageAvailableInsights"
                    value-field="name" text-field="name" placeholder="Select Insight"
                    @change="onInsightChange" class="selector-thick" style="margin-top: 7px;" />

            </a-col>



        </a-row>

        <a-row :gutter="24" type="flex">

            <a-col :span="20" class="mb-24">

                <CardBasicLineChart v-if="currentSelectedInsight && SelectedInsightItems && !loading"
                    :title="currentSelectedInsight" :subTitle="currentSelectedInsight"
                    :items="SelectedInsightItems" :colors="['#1890FF', '#B37FEB']" :numDatasets="1" />

            </a-col>
        </a-row>

        <vue-loaders-ball-beat v-if="loading" color="green" scale="1"></vue-loaders-ball-beat>


    </a-card>
</div>

</sweet-modal>



    


       


        <sweet-modal ref="pagepostsmodal" @close="closeNested" title="Page Posts" width="70%">

            <div v-if="showNested">

                <a-tabs default-active-key="1">

                    <a-tab-pane key="1" tab="Created Posts" force-render>

                        <a-card>
                            <a-table :columns="postTableColumns" :data-source="currentPagePosts" :pagination="false"
                                :loading="loading">



                                <span slot="sent" slot-scope="text, record">

                                    <i v-if="record.sent" class="fa fa-check-circle-o" style="margin-left: 7px;"></i>

                                    <i v-if="!record.sent" class="fa fa-circle-o" style="margin-left: 7px;"></i>

                                </span>

                                <span slot="task_type" slot-scope="text, record">


                                    <i v-if="record.task_type == 'add_reel_facebook'" class="fa fa-film"
                                        style="margin-left: 7px;"></i>

                                    <i v-if="record.task_type == 'add_video_facebook'" class="fa fa-file-video-o"
                                        style="margin-left: 7px;"></i>

                                    <i v-if="record.task_type == 'add_photo_facebook'" class="fa fa-picture-o"
                                        style="margin-left: 7px;"></i>

                                    <i v-if="record.task_type == '' || !record.task_type" class="fa fa-clipboard"
                                        style="margin-left: 7px;"></i>

                                </span>

                                <span slot="preview_media_url" slot-scope="text, record">

                                    <b-card>

                                        <a-row type="flex">

                                            <a-col>

                                                <div v-if="record.task_type == 'add_reel_facebook'">

                                                    <video width="320" height="240" controls>
                                                        <source :src="record.preview_media_url" type="video/mp4">
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>

                                                <div v-if="record.task_type == 'add_video_facebook'">

                                                    <video width="320" height="240" controls>
                                                        <source :src="record.preview_media_url" type="video/mp4">
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>

                                                <div v-if="record.task_type == 'add_photo_facebook'">

                                                    <img :src="record.preview_media_url"
                                                        style="width: 320px; height: auto;">
                                                </div>

                                                <div v-if="record.task_type == '' || !record.task_type">

                                                    <img src="images/imageunavailable.jpg"
                                                        style="width: 320px; height: auto;">

                                                </div>






                                                <p>{{ record.message }}</p>

                                            </a-col>

                                            






                                        </a-row>

                                    </b-card>




                                </span>

                                <span slot="scheduled_ts" slot-scope="text, record">
                                    {{ record.scheduled_ts ? getrelativeTimeFromTs(record.scheduled_ts) : 'Direct Post'
                                    }}
                                </span>

                                <span slot="created" slot-scope="text, record">

                                    {{ getrelativeTimeFromDate(record.created) }}
                                    
                                </span>

                                <span slot="diagnostic" slot-scope="text, record">

                                    <a-button type="info" @click="openPostStats(record.id)" style="margin-right: 7px;">

                                        <i class="fa fa-pie-chart" style="margin-right: 7px;"></i> Post Stats
                                    </a-button>
                                </span>


                            </a-table>

                            <div class="card-footer">

                                <a-row>
                                    <a-col :span="20">
                                        <a-pagination :total="total_created_posts" :defaultPageSize="10"
                                            @change="onPageChange" :currentPage="currentCreatedPostsPage" />
                                    </a-col>
                                </a-row>
                            </div>

                        </a-card>
                    </a-tab-pane>



                    <a-tab-pane key="2" tab="Create A Post" force-render>

                        <a-card>
                            <a-row :gutter="24" type="flex">

                                <a-col :span="24" class="mb-24">

                                    <a-form :form="form" class="login-form" @submit="createPost">
                                        <a-form-item style="margin-top: 9px;">

                                            <b-form-select v-model="selectedPostType" :options="fbPostTypeOptions"
                                                value-field="value" text-field="label" placeholder="Select Post Type"
                                                class="selector-thick" />
                                        </a-form-item>

                                        <a-form-item
                                            v-if="selectedPostType == 'TEXT' || selectedPostType == 'REELS' || selectedPostType == 'IMAGE'">
                                            <a-input type="textarea" placeholder="Post Message" rows="5"
                                                v-model="newPostMessage" />
                                        </a-form-item>

                                        <a-form-item v-if="selectedPostType == 'TEXT'">
                                            <a-input placeholder="Post Link" v-model="newPostLink" />
                                        </a-form-item>

                                        <a-form-item v-if="selectedPostType == 'IMAGE'">
                                            <a-descriptions>

                                                <a-descriptions-item>
                                                    Upload PNG 3mb max:
                                                    <a-input placeholder="Image" type="file" name="photo"
                                                        v-model="newPostPhoto" />
                                                </a-descriptions-item>


                                            </a-descriptions>
                                        </a-form-item>

                                        <a-form-item v-if="selectedPostType == 'VIDEO'">

                                            <a-descriptions>
                                                <a-descriptions-item>
                                                    Upload Video:
                                                    <a-input placeholder="Video" type="file" v-model="newPostVideo"
                                                        name="video" />
                                                </a-descriptions-item>
                                            </a-descriptions>
                                        </a-form-item>

                                        <a-form-item v-if="selectedPostType == 'REELS'">

                                            <a-descriptions>
                                                <a-descriptions-item>
                                                    Upload Video 90 Seconds max with 9/16 Aspect Ratio:
                                                    <a-input placeholder="Video" type="file" v-model="newPostVideo"
                                                        name="video" />
                                                </a-descriptions-item>
                                            </a-descriptions>
                                        </a-form-item>

                                        <a-form-item v-if="selectedPostType == 'VIDEO'">


                                            <a-descriptions>
                                                <a-descriptions-item>
                                                    Video Thumbnail PNG 1mb max:
                                                    <a-input placeholder="Thumbnail" type="file" name="thumb"
                                                        v-model="newPostThumb" />
                                                </a-descriptions-item>
                                            </a-descriptions>
                                        </a-form-item>


                                        <a-form-item>
                                            <a-input type="date" placeholder="Schedule Date"
                                                v-model="newPostScheduleDate" />
                                            <a-input type="time" placeholder="Schedule Time"
                                                v-model="newPostScheduleTime" />
                                        </a-form-item>

                                        <a-form-item>
                                            <a-input type="submit" block :disabled="!validatePostItem || loading">
                                                <i class="fa fa-facebook" style="margin: 7px;"></i> Create Post
                                            </a-input>
                                        </a-form-item>
                                    </a-form>

                                    <vue-loaders-ball-beat v-if="loading" color="green"
                                        scale="1"></vue-loaders-ball-beat>

                                </a-col>



                            </a-row>
                        </a-card>

                    </a-tab-pane>

                </a-tabs>



            </div>

        </sweet-modal>


        <sweet-modal ref="poststatsmodal" @close="closeNestedSub" title="Post Stats" width="70%">

<div v-if="showNestedSub">

    <a-card>

        <a-row :gutter="24" type="flex">

            <a-col :span="18" style="margin-top: 17px;">

                <br>

                <p>Post lifetime stats</p>
                <br>



                <b-select v-model="currentSelectedPostInsight" :options="currentPostAvailableInsights"
                    value-field="name" text-field="name" placeholder="Select Insight"
                    @change="onPostInsightChange" class="selector-thick" style="margin-top: 7px;" />

            </a-col>



        </a-row>

        <a-row :gutter="24" type="flex">

            <a-col :span="20" class="mb-24">

                <CardBasicLineChart
                    v-if="currentSelectedPostInsight && SelectedPostInsightItems && !loading"
                    :title="currentSelectedPostInsight" :subTitle="currentSelectedPostInsight"
                    :items="SelectedPostInsightItems" :colors="['#1890FF', '#B37FEB']" :numDatasets="1" chartType="bar" />

            </a-col>
        </a-row>

        <vue-loaders-ball-beat v-if="loading" color="green" scale="1"></vue-loaders-ball-beat>


    </a-card>


</div>

</sweet-modal>





        <a-row :gutter="24" type="flex">

            <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

            <a-col :span="20" class="mb-24">
                <!-- Header Search Input -->
                <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…"
                    @search="onSearch" :loading='searchLoading' v-model="searchTerm">
                    <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
                            fill="#111827" />
                    </svg>
                </a-input-search>
            </a-col>

            <a-col :span="4" class="mb-24">
                <a-button type="secondary" block style="margin-left: 9px;" @click="requestAddFbPage">
                    <i class="fa fa-facebook" style="margin-right: 7px;"></i> Add Facebook Page
                </a-button>
            </a-col>

        </a-row>



        <!-- Authors Table -->
        <a-row :gutter="24">

            <p v-if="facebookpages.length < 1">No items to display, check that you have data sources configured.</p>

            <!-- Authors Table Column -->
            <a-col :span="24">
                <a-table :columns="pageTableColumns" :data-source="facebookpages" :pagination="false"
                    :loading="loading">
                    <span slot="name" slot-scope="text, record">

                        <span class="ml-2">{{ record.name }}</span>
                    </span>
                    <span slot="category" slot-scope="text, record">
                        {{ record.category }}
                    </span>

                    <span slot="token_expired" slot-scope="text, record">
                        {{ record.token_expired ? 'Expired Token' : 'OK' }}
                    </span>

                    <span slot="access_token" slot-scope="text, record">

                        <a-button type="info" @click="openPagePosts(record.id)" style="margin-right: 7px;">

                            <i class="fa fa-file-o" style="margin-right: 7px;"></i> Page Posts
                        </a-button>

                        <a-button type="info" @click="openPageStats(record.id)" style="margin-right: 7px;">

                            <i class="fa fa-pie-chart" style="margin-right: 7px;"></i> Page Stats
                        </a-button>

                        <a-button type="secondary" @click="openInstagramPages(record.id)" style="margin-right: 7px;">

                            <i class="fa fa-instagram" style="margin-right: 7px;"></i> Instagram Pages
                        </a-button>

                        <a-button type="warning" @click="doRemovePage(record)">

                            <i class="fa fa-trash-o" style="margin-right: 7px;"></i> Remove Page
                        </a-button>
                    </span>
                </a-table>

            </a-col>
            <!-- / Authors Table Column -->



        </a-row>
        <!-- / Authors Table -->

        <br>

    </div>
</template>

<script>

// "Authors" table component.
import CardProjectDisplay from '../components/Cards/CardProjectDisplay';

// "Projects" table component.
import WidgetCustomer from '../components/Widgets/WidgetCustomer';

import WidgetProject from '../components/Widgets/WidgetProject';

import CardBasicLineChart from '../components/Cards/CardBasicLineChart';

import { fetchDataJSON, fetchPostJSON, uploadFile } from '../services/v1/services';

import { getCurrentUserAccountId, saveCurrentUserAccountId, saveCurrentUseraccountRole, getCurrentUseraccountId, getCurrentUseraccountRole, getUserInfo, clearUserInfo, relativeTimeFromTs } from '../services/v1/data_methods';


export default ({
    components: {
        CardProjectDisplay,
        WidgetProject,
        CardBasicLineChart,
    },
    beforeCreate() {
        // Creates the form and adds to it component's "form" property.
        this.form = this.$form.createForm(this, { name: 'normal_login' });
    },

    computed: {
        // Gets the current user's info.
        validatePostItem() {

            if (this.newPostScheduleDate == null || this.newPostScheduleTime == null) {
                return false;
            }

            if (this.selectedPostType == 'TEXT') {
                if (this.newPostMessage.length < 1) {
                    return false;
                }
            }
            else if (this.selectedPostType == 'IMAGE') {
                if (this.newPostPhoto == null) {
                    return false;
                }
            }
            else if (this.selectedPostType == 'VIDEO') {
                if (this.newPostVideo == null) {
                    return false;
                }
            }
            else if (this.selectedPostType == 'REELS') {
                if (this.newPostVideo == null) {
                    return false;
                }
            }

            return true;
        }
    },
    async mounted() {



        this.preferences = this.$store.getters.getPreferences;

		this.current_account_id = this.preferences.current_virtual_account;


        // console.log('current_account_id_pages', this.current_account_id, 'type', typeof this.current_account_id);


        if (this.current_account_id === null) {
            this.error = 'Nothing to display. Please select a virtual account on the side menu first.';

            return
        }




        const projectFacebookpagesRes = await fetchDataJSON('fb/' + this.$route.params.id + '/pages/list?page=' + this.currentPage, true, this.$store);

        this.facebookpages = projectFacebookpagesRes.data.pages;


        this.total_facebookpages = 10;

        this.$eventHub.$on("currentProjectUpdated", async (item) => {
            console.log('currentProjectUpdated indir', item);

            this.current_account_id = item.value;

            const projectFacebookpagesRes = await fetchDataJSON('fb/' + this.$route.params.id + '/pages/list?page=' + this.currentPage, true, this.$store);

            this.facebookpages = projectFacebookpagesRes.data.pages;
        });

        this.loading = false;
    },
    methods: {


        async onPostInsightChange(value) {
            console.log('onPostInsightChange', value);

            this.SelectedPostInsightItems = []

            this.currentSelectedPostInsight = value;

            this.loading = true;

            this.SelectedPostInsightItems = this.currentPostInsights[value].items;

            this.loading = false;
        },

        async onInsightChange(value) {
            console.log('onInsightChange', value);

            this.SelectedInsightItems = []

            this.currentSelectedInsight = value;

            this.loading = true;

            this.SelectedInsightItems = this.currentPageInsights[value].items;

            // const currentPageInsightsRes = await fetchDataJSON('fb/pages/' + this.selectedPageId + '/insights', true);

            // this.currentPageInsights = currentPageInsightsRes.data.insights;

            this.loading = false;
        },

        async doRemovePage(page) {
            console.log('doRemovePage', page);

            this.showNested = true;

            this.selectedDeletePage = page;

            this.$refs.pageremovemodal.open();
        },

        async removePage(page) {
            console.log('removePage', page);

            this.loading = true;

            const res = await fetchPostJSON('fb/pages/' + this.selectedDeletePage.id + '/ops/remove', page, true, this.$store);

            console.log(res);



            if (res.Ok) {
                this.$message.success('Page removed successfully');

                const projectFacebookpagesRes = await fetchDataJSON('fb/' + this.$route.params.id + '/pages/list?page=' + this.currentPage, true, this.$store);

                this.facebookpages = projectFacebookpagesRes.data.pages;

                this.loading = false;

                this.$refs.pageremovemodal.close();

                this.selectedDeletePage = null;
            }

            else {
                this.$message.error('Failed to remove page');

                this.loading = false;
            }
        },

        async createPost(e) {

            e.preventDefault();

            // console.log('createPost', this.newPostMessage, this.newPostLink, this.newPostPhoto, this.newPostVideo, this.newPostThumb, this.newPostScheduleDate, this.newPostScheduleTime);


            // schedule date and time

            if (this.newPostScheduleDate == null || this.newPostScheduleTime == null) {
                this.$message.error('Please select a schedule date and time');

                return;
            }

            const scheduleDate = new Date(this.newPostScheduleDate + ' ' + this.newPostScheduleTime);

            const scheduleDateUnix = scheduleDate.getTime() / 1000;

            console.log('scheduleDate', scheduleDate, '_', scheduleDateUnix);


            let sendRes = null;



            if (this.selectedPostType == 'TEXT') {
                console.log('TEXT');

                if (this.newPostMessage.length < 1) {
                    this.$message.error('Please enter a message');

                    return;
                }

                const addr = 'fb/' + this.selectedPageId + '/posts/add_post';

                const data = {
                    message: this.newPostMessage,
                    schedule_time: scheduleDateUnix,
                    link: this.newPostLink,
                };

                this.loading = true;

                sendRes = await fetchPostJSON(addr, data, true, this.$store);

                console.log('sendRes', sendRes);

            }
            else if (this.selectedPostType == 'IMAGE') {
                console.log('IMAGE');

                if (this.newPostPhoto == null) {
                    this.$message.error('Please select an image');

                    return;
                }

                const addr = 'fb/' + this.selectedPageId + '/posts/add_photo_post';

                const params = {};

                const data = new FormData();

                data.append('schedule_time', scheduleDateUnix);

                if (this.newPostMessage) {
                    data.append('message', this.newPostMessage);
                }

                const fileData = e.target.photo.files[0];

                console.log('this.newPostPhoto:', fileData);

                data.append('photo', fileData);

                this.loading = true;

                sendRes = await uploadFile(addr, data, params, true, this.$store);

                console.log('sendRes', sendRes);

                // this.loading = false;


            }
            else if (this.selectedPostType == 'VIDEO') {
                console.log('VIDEO');

                if (this.newPostVideo == null) {
                    this.$message.error('Please select a video');

                    return;
                }

                const data = new FormData();

                const params = {};

                data.append('schedule_time', scheduleDateUnix);

                if (this.newPostMessage) {
                    data.append('message', this.newPostMessage);
                }

                const addr = 'fb/' + this.selectedPageId + '/posts/add_video_post';

                const fileData = e.target.video.files[0];

                console.log('this.newPostvideo:', fileData);

                data.append('video', fileData);

                data.append('media_type', 'VIDEO');

                if (this.newPostThumb) {
                    const thumbFileData = e.target.thumb.files[0];

                    console.log('this.newPostThumb:', thumbFileData);

                    data.append('thumb', thumbFileData);
                }

                // add params



                this.loading = true;

            

                sendRes = await uploadFile(addr, data, params, true, this.$store);

                console.log('sendRes', sendRes);


            }
            else if (this.selectedPostType == 'REELS') {
                console.log('REELS');

                if (this.newPostVideo == null) {
                    this.$message.error('Please select a video');

                    return;
                }

                const params = {};

                const data = new FormData();

                data.append('schedule_time', scheduleDateUnix);



                const addr = 'fb/' + this.selectedPageId + '/posts/add_video_post';

                const fileData = e.target.video.files[0];

                console.log('this.newPostvideo:', fileData);

                data.append('video', fileData);

                if (this.newPostMessage) {
                    data.append('message', this.newPostMessage);
                }

                data.append('media_type', 'REELS');

                this.loading = true;

                sendRes = await uploadFile(addr, data, params, true, this.$store);

                console.log('sendRes', sendRes);


            }

            if (sendRes.Ok) {
                this.$message.success('Post created successfully');

                this.loading = false;

                this.$refs.pagepostsmodal.close();

                this.showNested = false;
            }

            else {
                this.$message.error('Failed to create post');

                this.loading = false;
            }
        },

        getrelativeTimeFromTs(ts) {
            return relativeTimeFromTs(ts);
        },

        getrelativeTimeFromDate(date) {

            let unixts = new Date(date).getTime() / 1000;
            let val = relativeTimeFromTs(unixts);

            return val;
        },

        async onPageChange(page) {
            console.log('onPageChange', page);

            this.loading = true;

            this.currentCreatedPostsPage = page;

            const currentPagePostsRes = await fetchDataJSON('fb/' + this.selectedPageId + '/posts/list?page=' + page, true, this.$store);

            this.currentPagePosts = currentPagePostsRes.data.posts;

            this.loading = false;
        },


        async openPostStats(postid) {

            this.loading = true;

            this.showNestedSub = true;

            // this.$refs.pagepostsmodal.close();

            this.$refs.poststatsmodal.open();

            const currentPostInsightsRes = await fetchDataJSON('fb/posts/' + postid + '/insights', true, this.$store);

            if(!currentPostInsightsRes.Ok){
                this.$message.error('Failed to get post insights, make sure the post is live and has been sent to facebook.');
                this.loading = false;
                this.$refs.poststatsmodal.close();
                return;
            }

            this.currentPostInsights = currentPostInsightsRes.data.insights;

            this.currentPostAvailableInsights = currentPostInsightsRes.data.available_insights;

            this.currentSelectedPostInsight = this.currentPostAvailableInsights ? this.currentPostAvailableInsights[0] : null;

            this.SelectedPostInsightItems = this.currentPostInsights[this.currentSelectedPostInsight].items;

            this.loading = false;
        },


        async openPageStats(pageid) {


            this.loading = true;

            this.showNested = true;

            this.$refs.pagestatsmodal.open();


            const currentPageInsightsRes = await fetchDataJSON('fb/pages/' + pageid + '/insights', true, this.$store);


            this.currentPageInsights = currentPageInsightsRes.data.insights;

            this.currentPageAvailableInsights = currentPageInsightsRes.data.available_insights;

            this.currentSelectedInsight = this.currentPageAvailableInsights ? this.currentPageAvailableInsights[0] : null;

            this.SelectedInsightItems = this.currentPageInsights[this.currentSelectedInsight].items;


            this.loading = false;
        },

        async openPagePosts(pageid) {

            this.newPostPhoto = null;

            this.newPostVideo = null;

            this.selectedPageId = pageid;

            this.currentPagePosts = [];

            this.loading = true;

            this.showNested = true;

            this.currentPageId = pageid;

            this.$refs.pagepostsmodal.open();

            const currentPagePostsRes = await fetchDataJSON('fb/' + pageid + '/posts/list', true, this.$store);

            this.currentPagePosts = currentPagePostsRes.data.posts;

            this.total_created_posts = currentPagePostsRes.data.total.tbl_row_count;

            this.loading = false;



            // this.form = this.$form.createForm(this, { name: 'post_create' });
        },
        openInstagramPages(pageid) {

            this.$router.push('/facebookaccounts/' + this.$route.params.id + '/facebookpages/' + pageid + '/instagrampages');
        },
        async doAddPage(id) {
            console.log('doAddPage', id);

            this.loading = true;

            // find the live page with the id

            const page = this.livePages.find(p => p.id == id);

            console.log('page', page);

            if (page) {
                const res = await fetchPostJSON('fb/' + this.$route.params.id + '/pages/add', page, true, this.$store);

                console.log(res);

                if (res.Ok) {
                    this.$message.success('Page added successfully');

                    const projectFacebookpagesRes = await fetchDataJSON('fb/' + this.$route.params.id + '/pages/list?page=' + this.currentPage, true, this.$store);

                    this.facebookpages = projectFacebookpagesRes.data.pages;

                    this.loading = false;

                    this.$refs.createpagemodal.close();


                }

                else {
                    this.$message.error('Failed to add page');

                    this.loading = false;
                }
            }
        },
        accountTypeDisplay(account_type) {
            return account_type == 1 ? 'Facebook' : 'TikTok';
        },


        async requestAddFbPage() {
            this.loading = true;

            const res = await fetchDataJSON('fb/' + this.$route.params.id + '/pages/refresh', true, this.$store);

            console.log(res);

            if (res.Ok) {
                this.$message.success('Request sent successfully, opening list of facebook pages');

                this.livePages = res.data.pages;



                this.showNested = true;

                this.$refs.createpagemodal.open();

                // window.open(res.data.login_url, '_blank');
            }

            else {
                this.$message.error('Request failed, please check status of token');
            }

            this.loading = false;
        },

        initCreateProjectChannelModal() {
            this.showNested = true;
            this.$refs.createprojectchannelmodal.open();
        },
        closeNested() {
            this.showNested = false;
        },
        closeNestedSub() {
            this.showNestedSub = false;
        },
        async onSearch(value) {
            this.searchLoading = true;

            console.log('searching', value);

            // const projectFacebookpagesRes = await fetchDataJSON('projects/' + this.current_account_id + '/Facebookpages/list?term=' + value , true);

            // this.facebookpages = projectFacebookpagesRes.data.facebookpages;

            this.searchLoading = false;
        },
    },
    data() {
        return {

            facebookpages: [],

            currentPagePosts: [],

            currentPageId: null,

            loading: true,

            error: null,

            form: null,

            searchLoading: false,

            showNested: false,

            currentPage: 1,

            currentCreatedPostsPage: 1,

            selectedDeletePage: null,

            total_created_posts: 0,

            fbPostTypeOptions: [
                { label: 'Text', value: 'TEXT' },
                { label: 'Image', value: 'IMAGE' },
                { label: 'Video', value: 'VIDEO' },
                { label: 'Reel', value: 'REELS' },
            ],
            postTableColumns: [
                {
                    title: 'preview',
                    dataIndex: 'preview_media_url',
                    key: 'preview_media_url',
                    scopedSlots: { customRender: 'preview_media_url' },
                },

                {
                    title: 'sent',
                    dataIndex: 'sent',
                    key: 'sent',
                    scopedSlots: { customRender: 'sent' },
                },
                {
                    title: 'post type',
                    dataIndex: 'task_type',
                    key: 'task_type',
                    scopedSlots: { customRender: 'task_type' },
                },


                {
                    title: 'scheduled at',
                    dataIndex: 'scheduled_ts',
                    key: 'scheduled_ts',
                    scopedSlots: { customRender: 'scheduled_ts' },
                },

                {
                    title: 'created',
                    dataIndex: 'created',
                    key: 'created',
                    scopedSlots: { customRender: 'created' },
                },

                {
                    title: 'actions',
                    dataIndex: 'diagnostic',
                    key: 'diagnostic',
                    scopedSlots: { customRender: 'diagnostic' },
                },


            ],

            pageTableColumns: [

                {
                    title: 'name',
                    dataIndex: 'name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'category',
                    dataIndex: 'category',
                    key: 'category',
                    scopedSlots: { customRender: 'category' },
                },

                {
                    title: 'Logged In Status',
                    dataIndex: 'token_expired',
                    key: 'token_expired',
                    scopedSlots: { customRender: 'token_expired' },
                },

                {
                    title: 'action',
                    dataIndex: 'access_token',
                    key: 'access_token',
                    scopedSlots: { customRender: 'access_token' },
                },


            ],

            selectedPostType: 'TEXT',

            selectedChannelProvider: 'twilio',

            newProjectName: '',

            newProjectDescription: '',

            total_facebookpages: 0,

            searchTerm: '',

            livePages: [],

            newPostMessage: '',
            newPostLink: '',
            newPostPhoto: null,
            newPostVideo: null,
            newPostThumb: null,
            newPostScheduleDate: null,
            newPostScheduleTime: null,
            current_account_id: null,
            currentPageAvailableInsights: [],
            currentSelectedInsight: null,

            currentSelectedPostInsight: null,
            currentPostAvailableInsights: [],
            SelectedPostInsightItems: [],
            showNestedSub: false,

        }
    },
})

</script>

<style lang="scss">
.selector-thick {
    width: 100%;

    height: 40px
}
</style>